import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";

export default function MonthlyGraph(props) {
  const {
    activeMeasure = "",
    graphData = {},
    setActiveSelection,
    setGraphData,
    setRows,
  } = props;
  const { datasets = [] } = graphData;
  const { data = [], scansBrokenUpByDay } = datasets[0] ? datasets[0] : {};
  const [chcolor, setChColor] = useState("");

  useEffect(() => {
    switch (activeMeasure) {
      case "Pick":
        setChColor("rgba(155,251,152,0.7)");
        break;
      case "Receiving":
        setChColor("rgba(245,222,179,1.0)");
        break;
      case "Shipping":
        setChColor("rgba(224,255,255,0.7)");
        break;
      case "Stow":
        setChColor("rgba(135,206,235,1.0)");
        break;
      default:
        setChColor("rgba(100,220,220,1)");
    }
  }, [activeMeasure]);

  return (
    <Bar
      data={graphData}
      height={90}
      options={{
        legend: { display: false, position: "top" },
        onClick: (evt, element) => {
          if (element[0]) {
            const { _index, _view } = element[0];
            const { label } = _view;

            // Sets the label above the table
            setActiveSelection(
              activeMeasure +
                " - " +
                graphData.labels[_index] +
                "  (Event Count:" +
                graphData.datasets[0].data[_index] +
                ")"
            );

            // Sets what rows are show on the table
            setRows(scansBrokenUpByDay[label]);

            // Changes the bar colors
            setGraphData(prevState=>{
              const newGraphData = prevState
              const { datasets = [] } = newGraphData
              const newBackgroundColors = datasets[0].backgroundColor || []
              newBackgroundColors.fill(chcolor)
              newBackgroundColors[_index] = "rgba(10,20,240,0.5)";
              return {
                ...prevState,
                datasets: [
                  {
                    ...prevState.datasets[0],
                    backgroundColor: newBackgroundColors
                  }
                ]
              }
              
            })

          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                max:
                  data.reduce(function (a, b) {
                    return Math.max(a, b);
                  }) || 100,
              },
            },
          ],
        },
        title: { display: false, maintainAspectRation: false, fontSize: 14 },
      }}
    />
  );
}
