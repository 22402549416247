export const retrieveTagTrack = async(props) => {
  // const {apiUrl,csmToken} = props
  // const body = {
  //   csmToken,
  // }

  // const results = await fetch(
  //   `${apiUrl}assetHistories/count`,
  //   {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "auth-token": "2984057d-229d-4b62-a17b-b5c3ce8303a4",
  //     },
  //     body: JSON.stringify(body),
  //   }
  // )
  // .then((response) => response.json())
  // .then((response) => {
  //   return 'Retrieved Tag Track'
  // })

  // return results
  return "hello world"
}