import React from "react";
import { Grid, TextField } from "@material-ui/core/";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { createGraphSettings } from "./api/api";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0 3px 5px 2px rgba(15,15,15, .4)",
    display: "inline-block",
    padding: "4px",
    width: "100%",
  },
  paper: {
    color: theme.palette.text.secondary,
    minWidth: 250,
    padding: theme.spacing(2),
    textAlign: "left",
  },
}));

const DarkerDisabledTextField = withStyles({
  root: {
    marginRight: 8,
    "& .MuiInputBase-root.Mui-disabled": {
      color: "black", // (default alpha is 0.38)
    },
  },
})(TextField);

export default function DailyMetrics(props) {
  const {
    activeMeasure,
    currentDate,
    setActiveSelection,
    setActiveMeasure,
    setGraphData,
    setRows,
    srows,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ backgroundColor: "snow" }}>
      <Grid container spacing={1}>
        {/* Receiving Events */}
        <Grid item xs={12}>
          <DarkerDisabledTextField
            color="secondary"
            disabled
            fullWidth={true}
            id="DlyRcvCnt"
            inputProps={{ style: { textAlign: "right", cursor: "pointer" } }}
            label="Receiving Events"
            margin="dense"
            onClick={() => {
              setActiveSelection(`Receiving - ${currentDate}`);
              setActiveMeasure("Receiving");
              setGraphData(createGraphSettings(srows.receivingEvents));
              // setRows(srows.receivingEvents.recentScans);
              setRows(createGraphSettings(srows.receivingEvents).datasets[0]
              .scansBrokenUpByDay[currentDate])
            }}
            style={{
              backgroundColor:
                activeMeasure === "Receiving"
                  ? "rgba(245,222,179,1.0)"
                  : "whitesmoke",
            }}
            // value={srows.receivingEvents.recentScanCount}
            value={
              createGraphSettings(srows.receivingEvents).datasets[0]
                .scansBrokenUpByDay[currentDate].length
            }
            variant="outlined"
          />
        </Grid>

        {/* Stow Events */}
        <Grid item xs={12}>
          <DarkerDisabledTextField
            disabled
            fullWidth={true}
            id="DlyStwCnt"
            inputProps={{ style: { textAlign: "right", cursor: "pointer" } }}
            label="Stow Events"
            margin="dense"
            onClick={() => {
              setActiveSelection(`Stow - ${currentDate}`);
              setActiveMeasure("Stow");
              setGraphData(createGraphSettings(srows.stowEvents));
              // setRows(srows.stowEvents.recentScans);
              setRows(createGraphSettings(srows.stowEvents).datasets[0]
              .scansBrokenUpByDay[currentDate])
            }}
            style={{
              backgroundColor:
                activeMeasure === "Stow"
                  ? "rgba(135,206,235,1.0)"
                  : "whitesmoke",
            }}
            // value={srows.stowEvents.recentScanCount}
            value={
              createGraphSettings(srows.stowEvents).datasets[0]
                .scansBrokenUpByDay[currentDate].length
            }
            variant="outlined"
          />
        </Grid>

        {/* Pick Events */}
        <Grid item xs={12}>
          <DarkerDisabledTextField
            disabled
            fullWidth={true}
            id="DlyPickCnt"
            inputProps={{ style: { textAlign: "right", cursor: "pointer" } }}
            label="Pick Events"
            margin="dense"
            onClick={() => {
              setActiveSelection(`Pick - ${currentDate}`);
              setActiveMeasure("Pick");
              setGraphData(createGraphSettings(srows.pickEvents));
              // setRows(srows.pickEvents.recentScans);
              setRows(createGraphSettings(srows.pickEvents).datasets[0]
              .scansBrokenUpByDay[currentDate])
            }}
            style={{
              backgroundColor:
                activeMeasure === "Pick"
                  ? "rgba(155,251,152,1.0)"
                  : "whitesmoke",
            }}
            // value={srows.pickEvents.recentScanCount}
            value={
              createGraphSettings(srows.pickEvents).datasets[0]
                .scansBrokenUpByDay[currentDate].length
            }
            variant="outlined"
          />
        </Grid>

        {/* Dissociate Events */}
        <Grid item xs={12}>
          <DarkerDisabledTextField
            disabled
            fullWidth={true}
            id="DlyShipCnt"
            inputProps={{ style: { textAlign: "right", cursor: "pointer" } }}
            label="Dissociate Events"
            margin="dense"
            onClick={() => {
              setActiveSelection(`Dissociate - ${currentDate}`);
              setActiveMeasure("Dissociate");
              setGraphData(createGraphSettings(srows.dissociateEvents));
              // setRows(srows.dissociateEvents.recentScans);
              setRows(createGraphSettings(srows.dissociateEvents).datasets[0]
              .scansBrokenUpByDay[currentDate])
            }}
            style={{
              backgroundColor:
                activeMeasure === "Dissociate"
                  ? "rgba(224,255,255,0.7)"
                  : "whitesmoke",
            }}
            // value={srows.dissociateEvents.recentScanCount}
            value={
              createGraphSettings(srows.dissociateEvents).datasets[0]
                .scansBrokenUpByDay[currentDate].length
            }
            variant="outlined"
          />
        </Grid>
      </Grid>
    </div>
  );
}
