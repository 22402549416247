import React, { useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { retrieveTagTrack } from "./api";
import { makeStyles } from "@material-ui/core/styles";
import TagTrackTable from "./TagTrackTable";

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: "1rem",
    height: "100%",
  },
}));

export default function TagTrack(props) {
  const [tagNumber, setTagNumber] = useState("");
  const [rows, setRows] = useState([
    createData(
      "2907263",
      "A311700-4",
      "BB030621BB",
      "8787015357878",
      "200784460001",
      "PELICAN BOX 27 GALLON"
    ),
    createData(
      "2907267",
      "A773447-1",
      "DF021644AA",
      "4215068557114",
      "229007-A6000B",
      "LIGHT PANEL 2X2 40W"
    ),
    createData(
      "2907202",
      "K221500-3",
      "BK011225DD",
      "8722035611404",
      "1100352260007A",
      "ULINE STORAGE RACK"
    ),
    createData(
      "2907288",
      "A311700-4",
      "BB030621BB",
      "8787015357878",
      "200784460001",
      "PELICAN BOX 27 GALLON"
    ),
  ]);
  const classes = useStyles();

  // This will be replaced with code that gets data
  function createData(
    stTagNo,
    stPartNo,
    stLocation,
    stNSN,
    stSerialNo,
    stDesc
  ) {
    return { stTagNo, stPartNo, stLocation, stNSN, stSerialNo, stDesc };
  }

  return (
    <Grid container>
      <Grid item xs={10}>
        <TextField
          fullWidth="true"
          id="stQueryString"
          // inputProps={{ style: { textAlign: "right" } }}
          label="Enter Tag Number"
          onChange={(event) => {
            setTagNumber(event.target.value);
          }}
          size="small"
          value={tagNumber}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={1}>
        <Button
          className={classes.button}
          color="primary"
          disabled={tagNumber.length === 0}
          onClick={() => {
            retrieveTagTrack({ ...props }).then((response) => {
              console.log(response);
              setRows([
                createData(
                  "2907267",
                  "A773447-1",
                  "DF021644AA",
                  "4215068557114",
                  "229007-A6000B",
                  "LIGHT PANEL 2X2 40W"
                ),
              ]);
            });
          }}
          variant="outlined"
        >
          Submit
        </Button>
      </Grid>
      <Grid item xs={12}>
        <h5>Tags</h5>
        <TagTrackTable rows={rows} />
      </Grid>
    </Grid>
  );
}
