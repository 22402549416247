import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TagActivity from "./pages/TagActivity/TagActivity";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";

// TODO: These two components need to be worked on after March 1st
// import Notifications from "./pages/Notifications/Notifications";
// import PartLocation from "./pages/PartLocations/PartLocation";

import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TagTrack from "./pages/TagTrack/TagTrack";

const apiUrl = process.env.REACT_APP_API_ENDPOINT;
const authToken = process.env.REACT_APP_AUTH_TOKEN;
const csmToken = process.env.REACT_APP_CSM_TOKEN;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      {...other}
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function App() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Tag Activity" {...a11yProps(0)} />
          <Tab label="Tag Track" {...a11yProps(1)} />

          {/* TODO: These two components need to be worked on after March 1st */}
          {/* <Tab label="Part Locations" {...a11yProps(2)} />
          <Tab label="Notifications" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TagActivity
          apiUrl={apiUrl}
          authToken={authToken}
          csmToken={csmToken}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TagTrack apiUrl={apiUrl} csmToken={csmToken} />
      </TabPanel>

      {/* TODO: These two components need to be worked on after March 1st */}
      {/* <TabPanel value={value} index={2}>
        <PartLocation />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Notifications />
      </TabPanel> */}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
