import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles({
  table: {
    background: "linear-gradient(90deg, #F3F8F8 40%, #F4F6F6 70%)",
    boxShadow: "0 3px 5px 2px rgba(15,15,15, .4)",
    fontSize: 12,
    minWidth: 1024,
    padding: "2px",
  },
  head: {
    background: "#b4b4b3",
    color: "red",
    fontSize: 18,
    height: 8,
    marginBottom: 2,
    minWidth: 650,
  },
});

export default function TagTrackTable(props) {
  const { rows } = props
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell align="left">Tag ID</TableCell>
            <TableCell align="left">Part No.</TableCell>
            <TableCell align="left">Location</TableCell>
            <TableCell align="center">NSN</TableCell>
            <TableCell align="center">Serial No.</TableCell>
            <TableCell align="left" width="450">
              Description
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.time}>
              <TableCell component="th" scope="row">
                {row.stTagNo}
              </TableCell>
              <TableCell align="left">{row.stPartNo}</TableCell>
              <TableCell align="left">{row.stLocation}</TableCell>
              <TableCell align="left">{row.stNSN}</TableCell>
              <TableCell align="left">{row.stSerialNo}</TableCell>
              <TableCell
                align="left"
                component="th"
                scope="row"
                style={{ width: "45%" }}
              >
                {row.stDesc}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
