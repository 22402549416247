import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 924,
    boxShadow: "0 3px 5px 2px rgba(15,15,15, .4)",
    padding: "1px",
    background: "linear-gradient(110deg, #F1F8F8 20%, #F8F6F6 80%)",
    fontSize: 18,
  },
  head: {
    background: "#24b4b3",
    color: "red",
    height: 1,
    fontSize: 24,
    minWidth: 675,
  },
});

export default function DailyEventTable(props) {
  const classes = useStyles();
  const { rows = [] } = props;
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell>
              <b>Time</b>
            </TableCell>
            <TableCell align="left">
              <b>
                Tag<br></br>ID
              </b>
            </TableCell>
            <TableCell align="left">
              <b>Location</b>
            </TableCell>
            <TableCell align="left">
              <b>NSN</b>
            </TableCell>
            <TableCell align="left">
              <b>
                Part<br></br>Number
              </b>
            </TableCell>
            <TableCell align="left">
              <b>
                Serial<br></br>Number
              </b>
            </TableCell>
            <TableCell align="left" width="400">
              <b>Description</b>
            </TableCell>
            <TableCell align="left">
              <b>
                Battery<br></br>Percent
              </b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,index) => (
            <TableRow key={`${row.time}-${index}`}>
              <TableCell component="th" scope="row" style={{ width: "73px" }}>
                {row.time}
              </TableCell>
              <TableCell
                align="left"
                component="th"
                scope="row"
                style={{ width: "70px" }}
              >
                {row.stTagID}
              </TableCell>
              <TableCell
                align="left"
                component="th"
                scope="row"
                style={{ width: "300px" }}
              >
                {row.stLocation}
              </TableCell>
              <TableCell
                align="left"
                component="th"
                scope="row"
                style={{ width: "85px" }}
              >
                {row.stNSN}
              </TableCell>
              <TableCell
                align="left"
                component="th"
                scope="row"
                style={{ width: "140px" }}
              >
                {row.stPartNo}
              </TableCell>
              <TableCell
                align="left"
                component="th"
                scope="row"
                style={{ width: "50px" }}
              >
                {row.stSerialNo}
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                {row.stDesc}
              </TableCell>
              <TableCell
                align="right"
                component="th"
                scope="row"
                style={{ width: "40px" }}
              >
                {row.stBattPct}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
